import { Injectable } from '@angular/core';

import { THubMethods } from '@core/models/hub-event.model';
import { BaseHub } from '@core/services/hubs/base.hub';

@Injectable()
export class SystemMainHub {
  constructor(public readonly baseHub: BaseHub) {
    baseHub.setConfig('claims', [THubMethods.DocsUploaded, THubMethods.ProtectionPlanDocsUploaded]);
    this.subscribeToUpdates();
  }

  startSignalR() {
    this.baseHub.startSignalR();
  }

  subscribeToUpdates() {}
}
