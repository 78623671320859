import { Injectable } from '@angular/core';

import { RouterConstants } from '@core/constants/router.constants';
import { Feature } from '@core/enums/feature.enum';
import { TPage } from '@core/enums/page.enum';
import { UserScope } from '@core/enums/user-scope.enum';
import { NavigationService } from '@core/services/navigation.service';
import { ScopeService } from '@core/services/scope.service';
import { NavigationInterface } from '@shared/modules/navigation/components/navigation/navigation.component';

@Injectable()
export class SystemNavigationService extends NavigationService {
  constructor(private readonly scopeService: ScopeService) {
    super();
  }
  getNavList(): NavigationInterface[] {
    return [
      {
        route: '/app/claim-documents',
        name: 'Claim Documents',
        features: [Feature.UploadAnyClaimFiles],
        child: [],
        icon: 'claim-documents',
      },
      {
        route: `/${TPage.App}/${TPage.ImageArchive}`,
        name: 'Image Archive',
        features: [Feature.SearchImageArchive],
        child: [],
        icon: 'icon-dataimport',
      },
      // {
      //   route: `/${TPage.App}/${TPage.SlumberlandData}`,
      //   name: 'Slumberland data',
      //   features: [Feature.ViewHistoricalData],
      //   child: [],
      //   icon: 'img-slumberland',
      // },
      {
        route: `/${TPage.App}/${TPage.DataImport}`,
        name: 'Data Import',
        features: [Feature.ManageDataImport],
        child: [],
        icon: 'icon-dataimport',
      },
      {
        route: `/${TPage.App}/${TPage.Sales}`,
        name: 'Sales',
        features: RouterConstants.features.sales,
        featureOptions: {
          some: true,
        },
        child: [
          {
            route: `/${TPage.App}/${TPage.Sales}/${TPage.Reports}`,
            name: 'Reports',
            features: [],
          },
        ],
        icon: 'img-reports',
      },
      {
        route: `/${TPage.App}/${TPage.Portals}`,
        name: 'Portals',
        features: RouterConstants.features.tenants,
        featureOptions: {
          some: true,
        },
        child: [
          {
            route: `/${TPage.App}/${TPage.Portals}/${TPage.RetailerConsumerPortal}`,
            name: 'Retailer/Consumer',
            features: [],
          },
          {
            route: `/${TPage.App}/${TPage.Portals}/${TPage.TechnicianPortal}`,
            name: 'Technician',
            features: [],
          },
          {
            route: `/${TPage.App}/${TPage.Portals}/${TPage.MjcPortal}`,
            name: 'Jewelry',
            features: [],
          },
        ],
        icon: 'icon-portals',
      },
      {
        route: '/app/admin',
        name: 'Admin',
        features: RouterConstants.features.admin,
        featureOptions: {
          some: true,
        },
        icon: 'admin',
        child: [
          {
            route: '/app/admin/users',
            name: 'Users',
            features: [Feature.ViewUsers],
          },
          {
            route: '/app/admin/portal-features',
            name: 'Portal Features',
            features: [Feature.ManageEnhancements],
          },
          {
            route: '/app/admin/faqs',
            name: 'FAQs',
            features: [Feature.ManageFAQs],
          },
          {
            route: '/app/admin/videos',
            name: 'Videos',
            features: [Feature.ManageTrainingVideos],
          },
        ],
      },
      {
        route: '/app/help',
        name: 'Help',
        isAllow: !this.scopeService.allowed(UserScope.Consumer),
        icon: 'help',
        child: [],
      },
    ];
  }
}
