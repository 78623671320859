import { Injectable } from '@angular/core';

import { UserScope } from '../enums/user-scope.enum';
import { UserStore } from '../store/user/user.store';

@Injectable({
  providedIn: 'root',
})
export class ScopeService {
  constructor(private readonly userStore: UserStore) {}

  allowed(scope: UserScope) {
    const session = this.userStore.getSessionStorage();
    if (session) {
      return session.scope === scope;
    }
  }

  allowedMany(scopes: UserScope[]): boolean {
    const session = this.userStore.getSessionStorage();
    return scopes.indexOf(session.scope) !== -1;
  }

  allowedSome(scopes: UserScope[]): boolean {
    let isAllowed = false;
    scopes.forEach(scope => {
      if (!isAllowed) {
        isAllowed = this.allowed(scope);
      }
    });
    return isAllowed;
  }
}
