import { importProvidersFrom } from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';
import { QuillModule } from 'ngx-quill';

import { CoreModule } from '@core/core.module';
import { MainHub } from '@core/services/hubs/main.hub';
import { NavigationService } from '@core/services/navigation.service';
import { PipesModule } from '@shared/pipes/pipes.module';

import { SYSTEM_APP_ROUTES } from './app.routes';
import { SystemMainHub } from './core/services/system-main.hub';
import { SystemNavigationService } from './core/services/system-navigation.service';

export const SYSTEM_APP = {
  providers: [
    importProvidersFrom(
      CoreModule,
      PipesModule,
      QuillModule.forRoot({
        format: 'html',
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'], // toggled buttons
            ['blockquote'],
            [{ list: 'ordered' }, { list: 'bullet' }],

            [{ header: 1 }, { header: 2 }],
            [{ indent: '-1' }, { indent: '+1' }],
            [{ direction: 'rtl' }],

            [{ size: ['small', false, 'large', 'huge'] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ align: [] }],
            ['clean'],
            ['link'],
          ],
        },
      }),
    ),
    provideRouter(
      SYSTEM_APP_ROUTES,
      // withPreloading(PreloadAllModules),
      // withDebugTracing(),
    ),
    provideAnimations(),
    {
      provide: MainHub,
      useClass: SystemMainHub,
    },
    {
      provide: NavigationService,
      useClass: SystemNavigationService,
    },
  ],
};
