import { Routes } from '@angular/router';

import { AuthGuard } from '@core/guards/auth.guard';
import { NonAuthGuard } from '@core/guards/nonauth.guard';
import { SignalRResolver } from '@core/resolvers/signalr.resolver';
import { tenantThemeResolver } from '@core/resolvers/tenant-theme.resolver';
import { TokenRefreshResolver } from '@core/resolvers/token-refresh.resolver';
import { NotFoundComponent } from '@shared/components/not-found/not-found.component';
import { RedirectComponent } from '@shared/components/redirect/redirect.component';


export const SYSTEM_APP_ROUTES: Routes = [
  { path: '', pathMatch: 'full', component: RedirectComponent },
  {
    path: 'app',
    loadChildren: () => import('./pages/shell/shell.module').then(m => m.SYSTEM_SHELL_ROUTES),
    resolve: { tokenRefreshTimeout: TokenRefreshResolver, signalR: SignalRResolver },
    canMatch: [AuthGuard],
  },
  {
    path: 'auth',
    loadChildren: () => import('../../th-common/app/route-modules/auth/auth.module').then(m => m.AuthModule),
  },
  {
    path: 'log-out',
    loadChildren: () => import('../../th-common/app/route-modules/log-out/log-out.module').then(m => m.LogOutModule),
  },
  {
    path: ':tenant',
    resolve: { theme: tenantThemeResolver },
    loadChildren: () => import('../../th-common/app/route-modules/public/public.module').then(m => m.PublicModule),
    canMatch: [NonAuthGuard],
  },
  { path: '**', component: NotFoundComponent },
];
